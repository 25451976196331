'use client';

import { Loader2 } from 'lucide-react';
import type { ComponentPropsWithoutRef } from 'react';
import { cn } from '~/lib';

type LoaderProps = ComponentPropsWithoutRef<typeof Loader2>;

export const Loader = ({ className, size = 24, ...props }: LoaderProps) => (
  // By Sam Herbert (@sherb), for everyone. More @ http://goo.gl/7AJzbL
  <Loader2 className={cn('animate-spin', className)} size={size} {...props} />
);
