'use client';

import type { PropsWithChildren } from 'react';
import { create } from 'zustand';
import { ProviderConfirmationDialog } from './ProviderConfirmationDialog';

export type ConfirmationDialogProps = {
  title: string;
  description?: string;
  actionLabel?: string;
  cancelLabel?: string;
  loading?: boolean;
  onAction?: () => Promise<unknown> | void;
  onCancel?: () => void;
  autoClose?: boolean;
  confirmationText?: string;
};

const isDialogActionAsync = (
  action: ConfirmationDialogProps['onAction']
): action is () => Promise<unknown> => {
  return action?.constructor.name === 'AsyncFunction';
};

const isDialogActionNotAsync = (
  action: ConfirmationDialogProps['onAction']
): action is () => void => {
  return action?.constructor.name === 'Function';
};

type DialogType = ConfirmationDialogProps & {
  id: string;
};

type ModalState = {
  modals: DialogType[];
  enqueueConfirmationModal: (params: ConfirmationDialogProps) => string;
  closeToast: (toastId: string) => void;
};

const useModalStore = create<ModalState>((set, get) => ({
  modals: [],
  enqueueConfirmationModal: ({
    autoClose = true,
    ...params
  }: ConfirmationDialogProps) => {
    const { closeToast } = get();

    const id = Math.random().toString(36).slice(2, 9);

    const onCancel = () => {
      if (autoClose) {
        closeToast(id);
      }
      params.onCancel?.();
    };

    const setLoading = (loading: boolean) => {
      set((state) => ({
        modals: state.modals.map((dialog) =>
          dialog.id === id ? { ...dialog, loading } : dialog
        ),
      }));
    };

    const dialog: DialogType = {
      ...params,
      onCancel,
      loading: false,
      onAction: () => {
        const onAction = params.onAction;

        if (!onAction) {
          onCancel();
        }
        // check if action is async
        if (isDialogActionAsync(onAction)) {
          setLoading(true);
          void onAction().finally(() => {
            onCancel();
            setLoading(false);
          });
        } else if (isDialogActionNotAsync(onAction)) {
          onAction();
          onCancel();
        }
      },
      id,
    };

    set((state) => ({
      modals: [...state.modals, dialog],
    }));

    return id;
  },
  closeToast: (dialogId: string) => {
    set((state) => ({
      modals: state.modals.filter((dialog) => dialog.id !== dialogId),
    }));
  },
}));

export const useModal = useModalStore;

export const alertDialog = {
  add: (params: ConfirmationDialogProps) => {
    useModalStore.getState().enqueueConfirmationModal(params);
  },
};

export const AlertModal = ({ children }: PropsWithChildren) => {
  const { modals } = useModalStore();

  const currentDialog = modals[0] as DialogType | undefined;

  return (
    <>
      {children}
      {currentDialog && <ProviderConfirmationDialog {...currentDialog} />}
    </>
  );
};
