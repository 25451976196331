import * as React from 'react';

import { cn } from '~/lib';

export type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          'flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
          className
        )}
        ref={ref}
        {...props}
      />
    );
  }
);
Input.displayName = 'Input';

export const InputUnit = React.forwardRef<
  HTMLInputElement,
  InputProps & {
    nextChildren?: React.ReactNode;
    prevChildren?: React.ReactNode;
    inputClassName?: string;
  }
>(
  (
    {
      className,
      prevChildren,
      type,
      nextChildren,
      disabled,
      inputClassName,
      ...props
    },
    ref
  ) => {
    return (
      <div
        className={cn(
          'flex w-full h-10 rounded-md border border-input bg-background text-sm ring-offset-background',
          'focus-within:outline-none focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2',
          {
            'cursor-not-allowed opacity-50': disabled,
          },
          className
        )}
      >
        {prevChildren && (
          <div
            className={cn(
              'pl-3 py-2 flex items-center justify-center',
              inputClassName
            )}
          >
            {prevChildren}
          </div>
        )}
        <input
          type={type}
          className={cn(
            'px-3 py-2 bg-transparent border-0 w-full',
            'focus-visible:outline-none',
            'file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground',
            inputClassName
          )}
          disabled={disabled}
          ref={ref}
          {...props}
        />
        {nextChildren && (
          <div className={cn('pr-3 py-2', inputClassName)}>{nextChildren}</div>
        )}
      </div>
    );
  }
);

InputUnit.displayName = 'InputUnit';

export { Input };
