'use client';

import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'next/navigation';
import { logger } from '~/lib/logger/logger';

export const SetAffiliateCookies = () => {
  const searchParams = useSearchParams();

  const affiliateId = searchParams.get('ref');

  useQuery({
    queryKey: ['set-affiliate-cookies', affiliateId],
    queryFn: async () => {
      if (!affiliateId) {
        return;
      }

      const result = await fetch('/api/v1/affiliates/set-cookies', {
        method: 'POST',
        body: JSON.stringify({
          affiliateId,
        }),
      });

      const json = await result.json();

      if (!result.ok) {
        throw new Error(result.statusText);
      }

      logger.debug('SetAffiliateCookies', json);
    },
  });

  return null;
};
